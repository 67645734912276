<template>
  <!-- Text Input -->
  <div :id="name" class="relative flex w-full flex-col items-start space-y-px">
    <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
    <label
      :ref="(el) => (refs[name].value = el as HTMLInputElement)"
      class="text-body-2 relative flex h-auto min-h-[40px] w-full flex-row flex-wrap items-center justify-between gap-2 rounded-xl bg-white px-4 py-2"
      :class="disabled ? 'cursor-default' : 'cursor-text'"
      @blur="disabled ? null : setInputFocused(false), (userTyped = false)"
    >
      <div
        class="absolute left-0 size-full rounded-xl border-[1.5px] border-solid outline-none transition-colors duration-200"
        :class="[
          error
            ? 'border-error-100 peer-hover/icon-prefix:border-error-100 peer-hover/icon:border-error-100 peer-hover/prefix:border-error-100 peer-hover/suffix:border-error-100 peer-hover:border-error-100 '
            : 'border-black-20 hover:border-primary-50 active:border-primary-120 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50  peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05 ',
          isInputFocused ? 'border-primary-120' : '',
          { '!border-none': !error && ghost && !modelValue?.length && !isInputFocused },
        ]"
      ></div>
      <div
        v-if="modelValue.length && isInputFocused"
        class="text-body-2 peer relative flex w-full flex-row flex-wrap gap-2 hover:z-10"
      >
        <div
          v-for="(chip, index) in modelValue"
          :key="index"
          class="flex h-7 flex-row items-center gap-2 rounded-lg bg-primary-10 px-1 py-[2px] hover:z-10"
        >
          {{ chip }}
          <UiIcon name="big-close" class="cursor-pointer text-black-70" size="xs" @click.stop="updateValue(chip)" />
        </div>
      </div>
      <div v-else-if="modelValue.length">
        <div class="flex flex-row items-center gap-2">
          <div class="flex h-7 flex-row items-center px-1 py-[2px]">
            {{ chipsUntilThreshold }}
          </div>
          <div
            v-if="modelValue.length > showMoreThreshold"
            class="flex h-7 flex-row items-center rounded-lg bg-primary-10 px-1 py-[2px]"
          >
            {{ modelValue.length - showMoreThreshold }} more
          </div>
        </div>
      </div>
      <textarea
        :id="name"
        :value="shallowValue"
        :text="shallowValue"
        :name="name"
        type="text"
        autocomplete="off"
        :placeholder="dynamicPlaceholder"
        :disabled="disabled"
        class="text-body-2 peer h-[20px] w-full flex-1 resize-none border-none bg-transparent outline-none placeholder:text-sm placeholder:font-normal placeholder:leading-5 disabled:z-10 disabled:text-black-60"
        :class="{
          'placeholder:text-black-40': error,
          'placeholder:text-black-100': multipleItemsEditableContent,
        }"
        @keydown.backspace="eraseWord"
        @keydown.enter="keyDownEnterInput"
        @keydown.space="keyDownEnterInput"
        @focus="disabled ? null : setInputFocused(true)"
        @input="input($event)"
      />
    </label>
    <div class="absolute -bottom-4 h-4 w-full">
      <transition name="fade" mode="out-in">
        <p v-if="error" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-error-100">
          {{ error }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: any
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  showIcon?: boolean
  error?: string
  ghost?: boolean
  showMoreThreshold?: number
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: [],
  label: '',
  placeholder: '',
  error: '',
  showMoreThreshold: 1,
})

const isInputFocused = ref<boolean>(false)
const shallowValue = ref<string>('')
const userTyped = ref<boolean>(false)

const multipleItemsEditableContent = computed(() => {
  let string = props.modelValue.toString().replace(',', '; ')

  if (string) string = string.concat(' ')
  return string
})

const dynamicPlaceholder = computed(() =>
  multipleItemsEditableContent.value ? '' : props.modelValue.length >= 3 ? 'Options Selected' : props.placeholder
)

const refs = {
  [props.name]: ref<HTMLInputElement>(),
}

const setInputFocused = (value: boolean) => {
  isInputFocused.value = value
}

const input = (event: Event) => {
  isInputFocused.value = true
  shallowValue.value = (event.target as HTMLInputElement).value
  userTyped.value = true
}

const eraseWord = () => {
  if (!shallowValue.value || shallowValue.value === '\n') updateValue(props.modelValue[props.modelValue.length - 1])
}

const keyDownEnterInput = () => {
  if (!shallowValue.value) return

  updateValue(shallowValue.value)
  shallowValue.value = ''
}

const updateValue = (value: string) => {
  if (!value || value === '\n' || value === ' ') return
  value = value.trim()

  userTyped.value = false

  setTimeout(() => {
    if (props.modelValue.includes(value)) {
      emits(
        'update:modelValue',
        props.modelValue.filter((l: string) => l !== value)
      )
    } else {
      emits('update:modelValue', Array.from(new Set([...props.modelValue, value])))
    }
  })
}

onMounted(() => {
  onClickOutside(refs[props.name], () => {
    if (isInputFocused.value) isInputFocused.value = false
    keyDownEnterInput()
  })
})

const chipsUntilThreshold = computed(() => {
  const chips = props.modelValue.slice(0, props.showMoreThreshold)
  return chips.join(' ')
})
</script>

<style scoped></style>
